import * as React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { CaseAdditionData } from '../../../data/CaseAdditionData';

const styles = (theme: Theme) => ({
    expansionPanel: {
        paddingLeft: theme.spacing.unit * 4,
        paddingRight: theme.spacing.unit * 4,
    },
    textBody: {
        whiteSpace: 'pre-line' as 'pre-line',
    }
});

export interface CaseAdditionProps {
    even: boolean,
    data: CaseAdditionData,
    onDelete: (id: number) => void,
    onEdit: (addition: CaseAdditionData) => void,
}

type Props = CaseAdditionProps & WithStyles<typeof styles>;

function CaseAddition(props: Props) {
    const { even, data, classes, onDelete, onEdit } = props;
    return (
        <ExpansionPanel elevation={0} style={even ? { backgroundColor: '#f8f8f8' } : {}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanel}>
                <Grid container spacing={16} alignItems='center'>

                    <Grid item xs={10} sm={2}>
                        <Typography component="p" color='textSecondary'>
                            Aangemaakt op
                            </Typography>
                        <Typography variant="subtitle1">
                            {data.timeStamp}
                        </Typography>
                    </Grid>

                    <Grid item xs={10} sm={2}>
                        <Typography component="p" color='textSecondary'>
                            Eerste behandelaar
                            </Typography>
                        <Typography variant="subtitle1">
                            {data.firstVolunteer.firstName + " " + data.firstVolunteer.lastName}
                        </Typography>
                    </Grid>

                    <Grid item xs={10} sm={2}>
                        <Typography component="p" color='textSecondary'>
                            Tweede behandelaar
                            </Typography>
                        <Typography variant="subtitle1">
                            {data.secondVolunteer.firstName + " " + data.secondVolunteer.lastName}
                        </Typography>
                    </Grid>

                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanel}>
                <Grid container spacing={32}>

                    <Grid item xs={12}>
                        <Typography component="p" color='textSecondary'>
                            Update
                            </Typography>
                        <Typography variant="body1" paragraph align='justify' className={classes.textBody}>
                            {data.entry}
                        </Typography>
                    </Grid>

                </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions className={classes.expansionPanel}>
                <Button color="secondary" onClick={() => onEdit(data)}>Aanpassen</Button>
                <Button onClick={() => onDelete(data.id)}>Verwijderen</Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
}

export default withStyles(styles)(CaseAddition);
