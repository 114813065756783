import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import TextInput from './Inputs/TextInput';
import GenderSelect from './Inputs/GenderSelect';
import AgeSelect from './Inputs/AgeSelect';
import DateInput from './Inputs/DateInput';
import { connect } from 'react-redux';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../utilities/UtilityString";
import { ClientData } from '../../data/ClientData';

const styles = (theme: Theme) => ({
    container: {
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    hidden: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
            paddingLeft: theme.spacing.unit * 30,
            paddingRight: theme.spacing.unit * 30,
        }
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface ClientFormProps {
    formId: string,
    apiEndpoint: string,
    onSubmitSuccess: () => void,
    onSubmitFail: () => void,
    data?: ClientData,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface ClientFormErrors {
    [key: string]: any,
    firstName: string,
    initials: string,
    email: string,
    phoneNumber: string,
    phoneNumberMobile: string,
    postalcode: string,
    age: string,
    bsn: string,
    notes: string,
}

export interface ClientFormState {
    id: number,
    firstName: string,
    lastName: string,
    lastNamePrefix: string,
    gender: string,
    dateOfBirth: string,
    placeOfBirth: string,
    email: string,
    phoneNumber: string,
    phoneNumberMobile: string,
    initials: string,
    streetName: string,
    houseNumber: string,
    postalCode: string,
    city: string,
    notes: string,
    age: string,
    primaryResidence: string,
    bsn: string,
    toestemming: string,
    errors: ClientFormErrors
}

type Props = ClientFormProps & WithStyles<typeof styles>;

class ClientForm extends React.Component<Props, ClientFormState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            id: props.data ? props.data!.id : -1,
            firstName: props.data ? props.data!.firstName : '',
            lastName: props.data ? props.data!.lastName : '',
            lastNamePrefix: props.data ? props.data!.lastNamePrefix : '',
            gender: props.data ? props.data!.gender : '',
            dateOfBirth: props.data ? props.data!.dateOfBirth : '',
            placeOfBirth: props.data ? props.data!.placeOfBirth : '',
            email: props.data ? props.data!.email : '',
            phoneNumber: props.data ? props.data!.phoneNumber : '',
            phoneNumberMobile: props.data ? props.data!.phoneNumberMobile : '',
            initials: props.data ? props.data!.initials : '',
            streetName: props.data ? props.data!.streetName : '',
            houseNumber: props.data ? props.data!.houseNumber : '',
            postalCode: props.data ? props.data!.postalCode : '',
            city: props.data ? props.data!.city : '',
            notes: props.data ? props.data!.notes : '',
            age: props.data ? props.data!.age : '',
            primaryResidence: props.data ? props.data!.primaryResidence : '',
            bsn: props.data ? props.data!.bsn : '',
            toestemming: props.data ? props.data!.toestemming : '',
            errors: {
                firstName: '',
                initials: '',
                email: '',
                phoneNumber: '',
                phoneNumberMobile: '',
                postalcode: '',
                age: '',
                bsn: '',
                notes: '',
            }
        };
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name as any]: event.target.value } as Pick<ClientFormState, keyof ClientFormState>);
    };

    handleSubmit = async (event: any) => {
        // prevent form from submitting itself.
        event.preventDefault();

        // If form isn't valid, return.
        if (!this.validate()) {
            return;
        }

        // If form has validated, extract form data and send to API server.
        const data = new FormData(event.target);
        const response: Response = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + this.props.apiEndpoint, data).perform();

        if (response.ok) {
            this.props.onSubmitSuccess();
        } else {
            this.props.onSubmitFail();
        }
    }

    validate = () => {
        let isValid = true;
        let currentErrors = { ...this.state.errors }
        const { initials, firstName, age, email, phoneNumber, phoneNumberMobile, postalCode, notes, bsn } = this.state;

        currentErrors.initials = UtilityString.nullOrEmpty(initials) ? "Initialen zijn verplicht" : "";
        currentErrors.firstName = UtilityString.nullOrEmpty(firstName) ? "Voornaam is verplicht" : "";

        // Age
        if (UtilityString.nullOrEmpty(age)) {
            currentErrors.age = "Leeftijd is verplicht";
        } else if (parseInt(age) < 4) {
            currentErrors.age = "Client mag niet jonger zijn dan 4 jaar";
        } else if (parseInt(age) > 23) {
            currentErrors.age = "Client mag niet ouder zijn dan 23 jaar";
        } else {
            currentErrors.age = "";
        }

        // Email
        const regxEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        if (!UtilityString.nullOrEmpty(email)) {
            currentErrors.email = !regxEmail.test(email) ? "Voer een geldig e-mailadres in" : "";
        } else {
            currentErrors.email = "";
        }

        // Phone number & Phone number mobile
        const regxPhoneNumber = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/igm;
        if (!UtilityString.nullOrEmpty(phoneNumber)) {
            currentErrors.phoneNumber = !regxPhoneNumber.test(phoneNumber) ? "Voer een geldig telefoonnummer in" : "";
        } else {
            currentErrors.phoneNumber = "";
        }

        const regxPhoneNumberMobile = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/igm;
        if (!UtilityString.nullOrEmpty(phoneNumberMobile)) {
            currentErrors.phoneNumberMobile = !regxPhoneNumberMobile.test(phoneNumberMobile) ? "Voer een geldig telefoonnummer in" : "";
        } else {
            currentErrors.phoneNumberMobile = "";
        }

        // Postal code
        const regxPostalCode = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/igm;
        if (!UtilityString.nullOrEmpty(postalCode)) {
            currentErrors.postalcode = !regxPostalCode.test(postalCode) ? "Voer een geldige postcode in" : "";
        } else {
            currentErrors.postalcode = "";
        }


        for (let key in currentErrors) {
            if (!UtilityString.nullOrEmpty(currentErrors[key])) {
                isValid = false;
                break;
            }
        }
        this.setState({ errors: currentErrors });
        return isValid;
    }

    public render() {
        const { classes, formId, data } = this.props;

        return (
            <div className={classes.root}>
                <form id={formId} className={classes.container} onSubmit={this.handleSubmit} autoComplete="off">
                    {data != undefined &&
                        <React.Fragment>
                            <Input id="Id" name="Id" value={data!.id} className={classes.hidden} />
                        </React.Fragment>
                    }

                    <Grid container spacing={24}>


                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="intitials-text"
                                name="initials"
                                label="Initialen"
                                value={this.state.initials}
                                onChange={this.handleChange}
                                required
                                error={this.state.errors.initials} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput
                                id="firstName-text"
                                name="firstName"
                                label="Voornaam"
                                value={this.state.firstName}
                                onChange={this.handleChange}
                                required
                                error={this.state.errors.firstName} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="lastNamePrefix-text"
                                name="lastNamePrefix"
                                label="Tussenvoegsel"
                                value={this.state.lastNamePrefix}
                                onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput
                                id="lastName-text"
                                name="lastName"
                                label="Achternaam"
                                value={this.state.lastName}
                                onChange={this.handleChange} />
                        </Grid>


                        <Grid item xs={12} sm={2}>
                            <GenderSelect
                                value={this.state.gender}
                                onChange={this.handleChange} />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <AgeSelect
                                onChange={this.handleChange}
                                value={this.state.age}
                                error={this.state.errors.age}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="dateOfBirth-date"
                                name="dateOfBirth"
                                label="Geboortedatum"
                                value={UtilityString.nullOrEmpty(this.state.dateOfBirth) ? "" : UtilityString.parseDateToYYYYMMDD(this.state.dateOfBirth)}
                                onChange={(event: any) => {
                                    this.handleChange(event);
                                    console.log(event.target.value);
                                    const difMs = Date.now() - new Date(event.target.value).getTime();
                                    const age = Math.abs(new Date(difMs).getUTCFullYear() - 1970);
                                    const ageString = (age > 23 || age < 4) ? "Onbekend" : age.toString();
                                    this.setState({ age: ageString });
                                }}
                                type='date'
                                defaultValue={undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextInput
                                id="placeOfBirth-text"
                                name="placeOfBirth"
                                label="Geboorteplaats"
                                value={this.state.placeOfBirth}
                                onChange={this.handleChange} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextInput
                                id="bsn-text"
                                name="bsn"
                                label="Burgerservicenummer"
                                value={this.state.bsn}
                                onChange={this.handleChange}
                                error={this.state.errors.bsn} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextInput
                                id="email-text"
                                name="email"
                                label="E-mailadres"
                                value={this.state.email}
                                onChange={this.handleChange}
                                error={this.state.errors.email} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput
                                id="phoneNumber-text"
                                name="phoneNumber"
                                label="Telefoonnummer"
                                value={this.state.phoneNumber}
                                onChange={this.handleChange}
                                error={this.state.errors.phoneNumber}
                                type='tel' />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput
                                id="phoneNumberMobile-text"
                                name="phoneNumberMobile"
                                label="Telefoonnummer (Mobiel)"
                                value={this.state.phoneNumberMobile}
                                onChange={this.handleChange}
                                error={this.state.errors.phoneNumberMobile}
                                type='tel' />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextInput
                                id="streetName-text"
                                name="streetName"
                                label="Straatnaam"
                                value={this.state.streetName}
                                onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="houseNumber-text"
                                name="houseNumber"
                                label="Huisnummer"
                                value={this.state.houseNumber}
                                onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="postalCode-text"
                                name="postalCode"
                                label="Postcode"
                                value={this.state.postalCode}
                                onChange={this.handleChange}
                                error={this.state.errors.postalcode} />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="city-text"
                                name="city"
                                label="Plaats"
                                value={this.state.city}
                                onChange={this.handleChange} />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <TextInput
                                id="primaryResidence-text"
                                name="primaryResidence"
                                label="Hoofdverblijfplaats"
                                value={this.state.primaryResidence}
                                onChange={this.handleChange} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput
                                id="notes-text"
                                name="notes"
                                label="Opmerkingen"
                                multiline
                                rows='3'
                                value={this.state.notes}
                                onChange={this.handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput
                                id="toestemming-text"
                                value={this.state.toestemming}
                                label="Toestemming"
                                name="toestemming"
                                multiline
                                rows="3"
                                onChange={this.handleChange}
                                error={this.state.errors.toestemming}
                            />
                        </Grid>

                    </Grid>
                </form>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ClientForm));
