import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PeopleIcon from '@material-ui/icons/People';
import PeopleIconOutline from '@material-ui/icons/PeopleOutline';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import JournalIcon from '@material-ui/icons/Assignment'
import CalendarIcon from '@material-ui/icons/Event'
import { Theme } from '@material-ui/core/styles';
import ReduxState from "../../../redux/ReduxState";
import { connect } from 'react-redux';
import Auth from "../../../auth/Auth";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import UtilityString from "../../../utilities/UtilityString";
import UtilityDocumentStore from "../../../utilities/UtilityDocumentStore";
import UtilityRole from "../../../utilities/UtilityRole";
import AuthRole from '../../../auth/AuthRole';

const styles = (theme: Theme) => ({
    toolbar: theme.mixins.toolbar
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface CustomDrawerProps {
    onNavigate: () => void;
    onChangePassword: () => void;
    onImportData: () => void;
    onImportAttachments: () => void;
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

type Props = CustomDrawerProps & WithStyles<typeof styles>;

class CustomDrawer extends React.Component<Props> {

    render() {
        const { classes, onNavigate, } = this.props;
        const { clientSettings } = this.props.configuration

        return (
            <div>
                <div className={classes.toolbar} />

                {UtilityRole.hasRole(this.props.user, AuthRole.ADMINISTRATOR) && (
                    <React.Fragment>
                        <Divider />
                        <List subheader={<ListSubheader component="div">Gebruikers</ListSubheader>}>
                            <Link to='/users' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItem button key='Actieve gebruikers' onClick={() => onNavigate()}>
                                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                                    <ListItemText primary='Actieve gebruikers' />
                                </ListItem>
                            </Link>
                            <Link to='/blockedusers' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItem button key='Geblokkeerde gebruikers' onClick={() => onNavigate()}>
                                    <ListItemIcon><PeopleIconOutline /></ListItemIcon>
                                    <ListItemText primary='Geblokkeerde gebruikers' />
                                </ListItem>
                            </Link>
                        </List>
                    </React.Fragment>
                )}

                <Divider />
                <List subheader={<ListSubheader component="div">Gegevens</ListSubheader>}>
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button key='Casussen' onClick={() => onNavigate()}>
                            <ListItemIcon><FolderIcon /></ListItemIcon>
                            <ListItemText primary='Casussen' />
                        </ListItem>
                    </Link>
                    <Link to='/clients' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button key='Cliënten' onClick={() => onNavigate()}>
                            <ListItemIcon><FolderSharedIcon /></ListItemIcon>
                            <ListItemText primary='Cliënten' />
                        </ListItem>
                    </Link>
                </List>

                {(clientSettings.useJournal || !UtilityString.nullOrEmpty(clientSettings.googleAgendaUrl)) && (
                    <React.Fragment>
                        <Divider />
                        <List subheader={<ListSubheader component="div">Administratie</ListSubheader>}>
                            {clientSettings.useJournal &&
                                <Link to='/journal' style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItem button key='Logboek' onClick={() => onNavigate()}>
                                        <ListItemIcon><JournalIcon /></ListItemIcon>
                                        <ListItemText primary='Logboek' />
                                    </ListItem>
                                </Link>
                            }
                            {!UtilityString.nullOrEmpty(clientSettings.googleAgendaUrl) &&
                                <Link to='/calendar' style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItem button key='Agenda' onClick={() => onNavigate()}>
                                        <ListItemIcon><CalendarIcon /></ListItemIcon>
                                        <ListItemText primary='Agenda' />
                                    </ListItem>
                                </Link>
                            }
                        </List >
                    </React.Fragment>
                )}

                {!UtilityString.nullOrEmpty(clientSettings.documentStores) &&
                    <React.Fragment>
                        <Divider />
                        <List subheader={<ListSubheader component="div">Documenten</ListSubheader>}>

                            {UtilityDocumentStore.parseDocumentStores(clientSettings.documentStores).map(store =>
                                <Link to={'/document/' + store.title} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItem button key={store.title} onClick={() => onNavigate()}>
                                        <ListItemIcon><FolderIcon /></ListItemIcon>
                                        <ListItemText primary={store.title} />
                                    </ListItem>
                                </Link>
                            )}

                        </List>
                    </React.Fragment>
                }

{/*UtilityRole.hasRole(this.props.user, AuthRole.DEVELOPER) && (
                    <React.Fragment>
                        <Divider />
                        <List subheader={<ListSubheader component="div">Developer</ListSubheader>}>
                                <ListItem button key='Import data' onClick={() => this.props.onImportData()}>
                                    <ListItemText primary='Import data' />
                                </ListItem>
                                <ListItem button key='Import attachments' onClick={() => this.props.onImportAttachments()}>
                                    <ListItemText primary='Import attachments' />
                                </ListItem>
                        </List>
                    </React.Fragment>
)*/}
            </div >
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CustomDrawer));