import * as React from 'react';
import { match } from 'react-router';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DocumentData } from '../../data/DocumentData';
import { DocumentStore } from '../../redux/entities/DocumentStore';
import UtilityDocumentStore from '../../utilities/UtilityDocumentStore';
import FileTable from '../components/table/FileTable';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../theme/Localization';
import ToolbarItemAdd from '../components/table/ToolbarItemAdd';
import AddGeneralDocumentDialog from '../components/dialogs/AddGeneralDocumentDialog';

const styles = (theme: Theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

    flexGrow: 1,
  },
  button: {
  },
  toolBar: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  grow: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: 20,
  },
});

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth!,
    user: state.user!,
    configuration: state.configuration!
  }
}

export interface DocumentsProps {
  match: match,
  history: History,
  auth: Auth,
  user: Oidc.User,
  configuration: ReduxConfigurationEntity
}

export interface DocumentsState {
  storeTitle: string,
  files: DocumentData[],
  store: DocumentStore | undefined,
  addDocumentDialogOpen: boolean,
  deleteDocumentId: number,
  deleteDocumentDialogOpen: boolean,
}

type Props = DocumentsProps & WithStyles<typeof styles>;

class Documents extends React.Component<Props, DocumentsState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      storeTitle: String((props.match.params as any).storeTitle),
      files: [],
      store: undefined,
      addDocumentDialogOpen: false,
      deleteDocumentId: -1,
      deleteDocumentDialogOpen: false,
    }
  }

  componentWillReceiveProps = (nextProps: Readonly<Props>, nextContext: any) => {
    const nextStoreTitle = String((nextProps.match.params as any).storeTitle);
    if (nextStoreTitle != this.state.storeTitle) {
      const currentStore = this.getCurrentStore(nextStoreTitle);
      this.setState({ storeTitle: nextStoreTitle, store: currentStore })
      this.loadDocumentsData(currentStore);
    }
  }

  componentDidMount = () => {
    const currentStore = this.getCurrentStore(this.state.storeTitle);
    this.setState({ store: currentStore });
    this.loadDocumentsData(currentStore);
  }

  loadDocumentsData = async (store: DocumentStore) => {
    const getDocuments = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/document/getminified?filter=" + store.filter).perform();
    await getDocuments.json().then((attachments: [any]) => {
      // Not sure why I'm doing it using object here but define a data class for the rest...
      let newData = attachments.map(entry => {
        const attachment: DocumentData = {
          id: entry.id,
          timestamp: entry.timestamp,
          fileName: entry.fileName,
          fileType: entry.fileType,
          note: entry.note,
        }
        return attachment;
      });
      this.setState({ files: newData });
    });
  }

  getCurrentStore = (storeTitle: string) => {
    const stores = UtilityDocumentStore.parseDocumentStores(this.props.configuration.clientSettings.documentStores)
    return stores.find(e => e.title == storeTitle)!
  }

  onAddDocumentSuccess = () => {
    this.loadDocumentsData(this.state.store!);
    this.setState({ addDocumentDialogOpen: false });
  }

  onDeleteDocumentConfirm = async (id: number) => {
    const deleteAdditionResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/document/delete?id=" + id).perform();
    if (deleteAdditionResponse.ok) {
      this.loadDocumentsData(this.state.store!);
    } else {
      // TODO: Show snackbar that something went wrong or something like that
    }
    this.setState({ deleteDocumentDialogOpen: false, deleteDocumentId: -1 });
  }

  onDelete = (id: number) => {
    this.setState({ deleteDocumentId: id, deleteDocumentDialogOpen: true });
  }

  onDownload = async (id: number) => {
    const file = this.state.files.find(e => e.id == id)!;
    const response = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/document/download?id=" + id).perform();

    response.blob().then(blob => {
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = file.fileName + file.fileType;
      link.click();
    });
  }

  public render() {
    const { storeTitle, files, addDocumentDialogOpen, deleteDocumentId, deleteDocumentDialogOpen, store } = this.state;

    const options: MUIDataTableOptions = {
      filter: false,
      search: false,
      responsive: "stacked",
      download: false,
      print: false,
      selectableRows: false,
      textLabels: TableTextLabels,
      rowsPerPageOptions: [5, 10, 20],
      customToolbar: () => {
        return (
          <ToolbarItemAdd
            tooltip="Document toevoegen"
            handleClick={() => this.setState({ addDocumentDialogOpen: true })}
          />
        );
      },
      elevation: 1,
    };


    return (
      <React.Fragment>
        <FileTable
          tableTitle={storeTitle}
          tableOptions={options}
          data={files}
          onDelete={this.onDelete}
          onDownload={this.onDownload}
        />

        {store != undefined && (
          <AddGeneralDocumentDialog
            storeFilter={store!.filter}
            open={addDocumentDialogOpen}
            onClose={() => this.setState({ addDocumentDialogOpen: false })}
            onSuccess={this.onAddDocumentSuccess}
          />
        )}

        {deleteDocumentId != -1 && (
          <ConfirmDialog
            additionId={deleteDocumentId}
            title="Document verwijderen?"
            message="Weet u zeker dat u dit document wil verwijderen? Dit kan niet ongedaan worden gemaakt."
            confirmButtonText="Verwijderen"
            open={deleteDocumentDialogOpen}
            onClose={() => this.setState({ deleteDocumentDialogOpen: false })}
            onConfirm={this.onDeleteDocumentConfirm}
          />
        )}

      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Documents));

