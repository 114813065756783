import * as React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TableTextLabels } from '../../theme/Localization';
import { connect } from 'react-redux';
import { History } from 'history';
import ReduxState from "../../redux/ReduxState";
import Auth from "../../auth/Auth";
import ApiRequest from "../../api/ApiRequest";
import HttpMethod from "../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../redux/entities/ReduxConfigurationEntity";
import ToolbarItemAdd from '../components/table/ToolbarItemAdd';
import UtilityString from '../../utilities/UtilityString';
import queryString from 'querystring';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { UserData } from '../../data/UserData';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from '@material-ui/icons/Create';
import TwoFAIcon from '@material-ui/icons/PhonelinkLock';
import ResetPasswordIcon from '@material-ui/icons/Undo';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import AddUserDialog from '../components/dialogs/AddUserDialog';
import EditUserDialog from '../components/dialogs/EditUserDialog';
import MessageDialog from '../components/dialogs/MessageDialog';

const columns = [
    {
        name: "ID",
        label: "ID",
        options: {
            display: 'excluded' as 'excluded',
            filter: false,
        }
    },
    {
        name: "FirstName",
        label: "Naam",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "Email",
        label: "E-mailadres",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "roles",
        label: "Rol(len)",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: false,
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                return (<React.Fragment />);
            },
            setCellProps: () => {
                return {
                    align: 'right',
                }
            }
        },
    },
];

const allowedTableChangedActions = ["filterChange", "resetFilters", "changePage", "changeRowsPerPage", "sort", "search"];

const styles = (theme: Theme) => ({
    fab: {
        margin: theme.spacing.unit,
        position: 'fixed' as 'fixed',
        right: 20,
        bottom: 20,
    },
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface ActiveUsersProps {
    history: History,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

export interface ActiveUsersState {
    data: UserData[],
    rowsPerPage: number,
    totalRowCount: number,
    addUserDialogOpen: boolean,
    editUserDialogOpen: boolean,
    editUserId: string,
    blockUserDialogOpen: boolean,
    blockUserId: string,
    reset2FADialogOpen: boolean,
    reset2FAUserId: string,
    resetPasswordDialogOpen: boolean,
    resetPasswordUserId: string,
    messageDialogOpen: boolean,
    messageDialogMessage: string,
}

type Props = ActiveUsersProps & WithStyles<typeof styles>;

class ActiveUsers extends React.Component<Props, ActiveUsersState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            rowsPerPage: 10,
            totalRowCount: 0,
            addUserDialogOpen: false,
            editUserDialogOpen: false,
            editUserId: "",
            blockUserDialogOpen: false,
            blockUserId: "",
            reset2FADialogOpen: false,
            reset2FAUserId: "",
            resetPasswordDialogOpen: false,
            resetPasswordUserId: "",
            messageDialogOpen: false,
            messageDialogMessage: "",
        };
    }

    componentDidMount = () => {
        const { rowsPerPage } = this.state;
        this.loadData(columns[0].name, 'desc', [], 0, rowsPerPage, '');
    }

    loadData = async (sortedColumn: string, sortDirection: string, columnFilters: string[], page: number, rowsPerPage: number, searchText: string) => {
        const requestBody = { sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText };
        const queryParams = queryString.stringify(requestBody)

        const getActiveUsers = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/user/getAllActiveUsers?" + queryParams).perform();
        await getActiveUsers.json().then(data => this.onDataReceived(data));
    }

    onTableChange = async (action: string, tableState: any) => {

        if (!allowedTableChangedActions.includes(action)) {
            return;
        }

        let sortedColumn: string = columns[0].name;
        let sortDirection: string = 'desc';
        let columnFilters: string[] = [];
        let page: number = tableState.page;
        let rowsPerPage: number = tableState.rowsPerPage;
        let searchText: string = tableState.searchText;

        if (tableState.activeColumn) {
            sortedColumn = tableState.columns[tableState.activeColumn].name;
            sortDirection = tableState.columns[tableState.activeColumn].sortDirection;
        }

        for (let i = 0; i < tableState.columns.length; i++) {
            const columnObject: any = tableState.columns[i];
            const filterValue = tableState.filterList[i][0];
            if (!UtilityString.nullOrEmpty(filterValue)) {
                columnFilters.push(columnObject.name + "," + filterValue);
            }
        }

        this.loadData(sortedColumn, sortDirection, columnFilters, page, rowsPerPage, searchText);
    }

    onDataReceived = (receivedData: any) => {
        let rowsPerPage = receivedData.rowsPerPage;
        let totalRowCount = receivedData.totalRowCount;
        let receivedDataEntries: [any] = receivedData.data;
        this.setState({ data: receivedDataEntries, totalRowCount, rowsPerPage });
    }

    onAddActiveUserSuccess = (generatedPassword: string) => {
        const passwordMessage: string = "Voor de nieuwe gebruiker is het volgende wachtwoord gegenereerd: " + generatedPassword
        this.setState({ addUserDialogOpen: false, messageDialogMessage: passwordMessage, messageDialogOpen: true })
        // Probably should just add the single case but this is easier when filters/searchqueries and sorting is used.
        // Should look into keeping the table state, although resetting the table state would make sense if you alter the data in it.
        this.loadData(columns[0].name, 'desc', [], 0, this.state.rowsPerPage, '');
    }

    onEditUserSuccess = () => {
        this.setState({ editUserDialogOpen: false, editUserId: "" });
        this.componentDidMount();
    }

    onConfirmBlockUser = async (id: string) => {
        const { blockUserId } = this.state;
        const blockUserResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwIdentity + "/account/blockuser?id=" + blockUserId).perform();
        if (blockUserResponse.ok) {
            const updateUserStateResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwAdminApi + "/user/userblocked?id=" + blockUserId).perform();
            this.componentDidMount();
        } else {
            // TODO: Show snackbar that something went wrong or something like that
        }
        this.setState({ blockUserDialogOpen: false, blockUserId: "" });
    }

    onConfirmResetPassword = async (id: string) => {
        const { resetPasswordUserId } = this.state;
        const resetPasswordResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwIdentity + "/account/resetuserpassword?id=" + resetPasswordUserId).perform();
        if (resetPasswordResponse.ok) {
            let passwordMessage = "";
            resetPasswordResponse.json().then(data => {
                const { newPassword } = data;
                passwordMessage = "Het wachtwoord van de gebruiker is opnieuw gegenereerd. Het wachtwoord is nu: " + newPassword;
                this.setState({ resetPasswordDialogOpen: false, resetPasswordUserId: "", messageDialogMessage: passwordMessage, messageDialogOpen: true });
            });
        } else {
            // TODO: Show snackbar that something went wrong or something like that
            this.setState({ resetPasswordDialogOpen: false, resetPasswordUserId: "" });
        }

    }

    onConfirmReset2FA = async (id: string) => {
        const { reset2FAUserId } = this.state;
        const reset2FAResponse = await new ApiRequest(this.props.user, HttpMethod.POST, this.props.configuration.endpoints.kjrwIdentity + "/twofactor/resetauthenticator?id=" + reset2FAUserId).perform();
        if (reset2FAResponse.ok) {
            this.componentDidMount();
        } else {
            // TODO: Show snackbar that something went wrong or something like that
        }
        this.setState({ reset2FADialogOpen: false, reset2FAUserId: "" });
    }

    renderActionColBody = (value: any, tableMeta: any, updateValue: any) => {
        return (
            <React.Fragment>
                <Tooltip title={"2FA resetten"}>
                    <IconButton onClick={() => this.setState({ reset2FADialogOpen: true, reset2FAUserId: tableMeta.rowData[0] })}>
                        <TwoFAIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Wachtwoord resetten"}>
                    <IconButton onClick={() => this.setState({ resetPasswordDialogOpen: true, resetPasswordUserId: tableMeta.rowData[0] })}>
                        <ResetPasswordIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Aanpassen"}>
                    <IconButton onClick={() => this.setState({ editUserDialogOpen: true, editUserId: tableMeta.rowData[0] })}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Blokkeren"}>
                    <IconButton onClick={() => this.setState({ blockUserDialogOpen: true, blockUserId: tableMeta.rowData[0] })}>
                        <BlockIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment >
        );
    }

    public render() {
        const { classes } = this.props;
        const { data, rowsPerPage, totalRowCount, blockUserId, blockUserDialogOpen, reset2FADialogOpen, reset2FAUserId, resetPasswordDialogOpen, resetPasswordUserId, editUserDialogOpen, editUserId, messageDialogOpen, messageDialogMessage } = this.state;

        // Have to set this here as there is no context in the global scope and the clicks require it.
        columns[4].options.customBodyRender = this.renderActionColBody

        const options: MUIDataTableOptions = {
            filter: false,
            filterType: "dropdown",
            responsive: "stacked",
            download: false,
            print: false,
            selectableRows: false,
            textLabels: TableTextLabels,
            count: totalRowCount,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [5, 10, 15, 20, 30, 50],
            customToolbar: () => {
                return (
                    <ToolbarItemAdd
                        tooltip="Gebruiker toevoegen"
                        handleClick={() => this.setState({ addUserDialogOpen: true })}
                    />
                );
            },
            serverSide: true,
            onTableChange: this.onTableChange,
        };

        const tableData = data.map(user => [user.id, user.firstName + " " + user.lastName, user.email, user.roles]);

        return (
            <div>
                <React.Fragment>
                    <MUIDataTable
                        title={"Actieve gebruikers"}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />

                    <AddUserDialog
                        open={this.state.addUserDialogOpen}
                        onClose={() => this.setState({ addUserDialogOpen: false })}
                        onSuccess={this.onAddActiveUserSuccess}
                    />

                    {!UtilityString.nullOrEmpty(editUserId) && (
                        <EditUserDialog
                            open={this.state.editUserDialogOpen}
                            data={data.find(user => user.id == editUserId)!}
                            onClose={() => this.setState({ editUserDialogOpen: false, editUserId: "" })}
                            onSuccess={this.onEditUserSuccess}
                        />
                    )}

                    {!UtilityString.nullOrEmpty(blockUserId) && (
                        <ConfirmDialog
                            additionId={blockUserId}
                            title="Gebruiker blokkeren?"
                            message="Door de gebruiker te blokkeren, kan deze persoon niet meer inloggen. Dit kan ongedaan worden gemaakt onder 'Geblokkerde gebruikers'"
                            confirmButtonText="Blokkeren"
                            open={blockUserDialogOpen}
                            onClose={() => this.setState({ blockUserDialogOpen: false, blockUserId: "" })}
                            onConfirm={this.onConfirmBlockUser}
                        />
                    )}

                    {!UtilityString.nullOrEmpty(reset2FAUserId) && (
                        <ConfirmDialog
                            additionId={reset2FAUserId}
                            title="2FA resetten?"
                            message="Hiermee wordt de bestaande 2FA code van de gebruiker verwijderd en moet deze dit opnieuw instellen bij de volgende inlogpoging."
                            confirmButtonText="Resetten"
                            open={reset2FADialogOpen}
                            onClose={() => this.setState({ reset2FADialogOpen: false, reset2FAUserId: "" })}
                            onConfirm={this.onConfirmReset2FA}
                        />
                    )}

                    {!UtilityString.nullOrEmpty(resetPasswordUserId) && (
                        <ConfirmDialog
                            additionId={resetPasswordUserId}
                            title="Wachtwoord resetten?"
                            message="Hiermee wordt het wachtwoord van de gebruiker opnieuw ingesteld."
                            confirmButtonText="Resetten"
                            open={resetPasswordDialogOpen}
                            onClose={() => this.setState({ resetPasswordDialogOpen: false, resetPasswordUserId: "" })}
                            onConfirm={this.onConfirmResetPassword}
                        />
                    )}

                    {!UtilityString.nullOrEmpty(messageDialogMessage) && (
                        <MessageDialog
                            message={messageDialogMessage}
                            open={messageDialogOpen}
                            onClose={() => this.setState({ messageDialogOpen: false, messageDialogMessage: "" })}
                        />
                    )}

                </React.Fragment>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ActiveUsers));
