import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import ReduxState from "../../../redux/ReduxState";
import Auth from "../../../auth/Auth";
import ApiRequest from "../../../api/ApiRequest";
import HttpMethod from "../../../http/enums/HttpMethod";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";

const styles = (theme: Theme) => ({
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 400,
    }
});

const mapStateToProps = (state: ReduxState) => {
    return {
        auth: state.auth!,
        user: state.user!,
        configuration: state.configuration!
    }
}

export interface VolunteerSelectState {
    options: { id: number, firstName: string, lastName: string }[],
}

export interface VolunteerSelectProps {
    name: string,
    label: string,
    placeholder: string,
    onChange: (event: any) => void,
    value: string,
    error?: string,
    required?: boolean,
    auth: Auth,
    user: Oidc.User,
    configuration: ReduxConfigurationEntity
}

type Props = VolunteerSelectProps & WithStyles<typeof styles>;

class VolunteerSelect extends React.Component<Props, VolunteerSelectState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            options: [],
        };
    }

    componentDidMount = async () => {
        const getClients = await new ApiRequest(this.props.user, HttpMethod.GET, this.props.configuration.endpoints.kjrwAdminApi + "/user/getall").perform();
        await getClients.json().then((data: any) => {
            // Filter current user out of list so they cannot select themselves.
            //let filteredData = data.value.filter((u: any) => u.id != this.props.user.profile.sub);    
            this.setState({ options: data.value});
        });
    }

    public render() {
        const { classes, onChange, value, error, name, label, placeholder, required } = this.props;
        const { options } = this.state;
        const id = name + "-select";
        return (
            <FormControl className={classes.formControl} fullWidth error={error != null && error != ''}>
                <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
                <Select
                    value={value}
                    input={<Input name={name} id={id} />}
                    onChange={onChange}
                    displayEmpty
                    name={name}
                    className={classes.selectEmpty}
                    placeholder={placeholder}
                >
                    {options.map(option =>
                        <MenuItem key={option.id} value={option.id}>{option.firstName + " " + option.lastName}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(VolunteerSelect));